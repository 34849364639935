import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {UseFormGetValues, UseFormRegister, Controller, UseFormSetValue} from 'react-hook-form';
import InputMask from 'react-input-mask';
import InputField from '../../../Field';

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>,
  disabled?: boolean
  id?: string

}

export default function PlateFieldForm({ label="Placa", register, control = {}, name, required = false, messageError = "", value = '', setValue, disabled = false, id = 'cpf_form' }: CrudTextFieldProps) {
  useEffect(()=> {
    if (value !== undefined && value !== null) {
      setValue(name, value)
    }
  }, [value])

 return (
    <Controller
    defaultValue={value}
    {...register(
      name,
      {
        required: { 
          value: required,
          message: messageError === '' ? 'Placa é obrigatório' : messageError,
        },
        pattern: {
          value: /[A-Za-z]/i,
          message: 'Placa inválida',
        },
      }
    )}
     name={name}
     control={control}
     render={({
      field,
      fieldState: { error },
    }) => {
      return (
       <InputMask
        mask={"aaa-9*99"}
        {...field}
        maskChar="_"
        disabled={disabled}
        >
        <InputField
          helperText={error && required? error.message : null}
          error={required?!!error: false}
          label={label}
          name={name}
          id={id}
          required={required}
        />
       </InputMask>
      );
     }}
    />
 );
}
