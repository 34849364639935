import { IDataTable } from "../../../components/CrudIndex";
import { authFetch } from "../../../services/fetch";
import { IError } from "../../Citizen/services";
import { ITrack } from "../interface";

export async function createTracker({
  plate,
  brand,
  trackCode,
  userId,
  keyword,
  trackChip,
  image,
  companyBranchId,
  authorization,
  appointment_id = null
}: ITrack) {
  try {
    const response = await authFetch({
      url: "/tracker/create",
      data: {
        plate: plate,
        brand: brand,
        trackCode: trackCode,
        keyword: keyword,
        userId: userId,
        chipCode: trackChip,
        image: image,
        company_branch_id: companyBranchId,
        authorization: authorization,
        appointment_id: appointment_id
        // location: [45.1234, -89.4567]
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ createTracker: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function listTrackerByUserId(idUser: any) {
  try {
    const response = await authFetch({
      url: `/tracker/user/${idUser}`,
      method: "GET",
    });
    return { hasError: false, response };
  } catch (error) {
    console.error("~ listTracker: ", error);
    return { hasError: true, error: error as { title: string } };
  }
}

export async function getLastTrackPosition(trackCode: string) {
  try {
    const response = await authFetch({
      url: `/tracker/last-position`,
      method: "POST",
      data: {
        trackCode: trackCode,
      },
    });
    return response.data;
  } catch (error) {
    console.error("~ getLastTrackPosition: ", error);
    throw error;
  }
}

export async function SearchTrack(plate: string, keyword: string) {
  try {
    const response = await authFetch({
      url: "/tracker/verify",
      data: {
        plate,
        keyword,
      },
    });
    return { hasError: false, response };
  } catch (error) {
    console.error("~ SearchTrack: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function createOccurrence(
  plate: string,
  keyword: string,
  ocurrence: string,
  comment: string
) {
  try {
    const response = await authFetch({
      url: "/tracker/create-external",
      data: {
        plate,
        keyword,
        occurrenceId: ocurrence,
        comment,
      },
    });
    return { hasError: false, response };
  } catch (error) {
    console.error("~ SearchTrack: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function getCitizenById(
  id: string
): Promise<readonly IDataTable[]> {
  try {
    const { data } = await authFetch({
      url: `/users/${id}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function editCitizen(id: string | undefined, data: any) {
  try {
    const response = await authFetch({
      url: `/users/update/${id}`,
      method: "PATCH",
      data: data,
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ editAttendant: ", error);
    return { hasError: true, error: error as IError };
  }
}

export async function getTrackerByTrackerCode(
  trackerCode: string
): Promise<ITrack> {
  try {
    const { data } = await authFetch({
      url: `/tracker-chip/list-by-tracker-code/${trackerCode}`,
      method: "GET",
    });
    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateTracker({
  id,
  plate,
  brand,
  trackCode,
  userId,
  keyword,
  trackChip,
  image,
  companyBranchId,
  authorization,
  appointment_id = null
}: ITrack) {
  try {
    const response = await authFetch({
      url:  `/tracker/update/${id}`,
      method: 'PATCH',
      data: {
        plate: plate,
        brand: brand,
        trackCode: trackCode,
        keyword: keyword,
        userId: userId,
        chipCode: trackChip,
        image: image,
        company_branch_id: companyBranchId,
        authorization: authorization,
        appointment_id: appointment_id
        // location: [45.1234, -89.4567]
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ updateTracker: ", error);
    return { hasError: true, error: error as IError };
  }
}
export async function listAllTracker(page = 0 , limit = 100, sort=[], filters={} ) {
  try {
    const response = await authFetch({
      url: `/tracker`,
      method: "GET",
      params: {
        page: page,
        limit: limit,
        filters: JSON.stringify(filters),
        //sort: JSON.stringify(sort)
      }
    });
    return { hasError: false, response };
  } catch (error) {
    console.error("~ listAllTracker: ", error);
    return { hasError: true, error: error as { title: string } };
  }
}

export async function getTrackById(id:number ) {
  try {
    const response = await authFetch({
      url: `/tracker/${id}`,
      method: "GET",
    });
    return { hasError: false, response };
  } catch (error) {
    console.error("~ getTrackById: ", error);
    return { hasError: true, error: error as { title: string } };
  }
}

export async function updatePlateTracker({
  id,
  plate,
}: any) {
  try {
    const response = await authFetch({
      url:  `/tracker/update/${id}`,
      method: 'PATCH',
      data: {
        plate: plate,
      },
    });
    return { hasError: false, data: response.data };
  } catch (error) {
    console.error("~ updatePlateTracker: ", error);
    return { hasError: true, error: error as IError };
  }
}
