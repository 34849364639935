import { Card, CardContent, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';

interface IAbsoluteNumbersCards {
    readonly color: string;
    readonly name: string;
    readonly value: number;
}

export default function AbsoluteNumbersCards({color, name, value}: IAbsoluteNumbersCards) {
  return (
    <Grid size={{sm: 4, md: 3, lg:2}}>
      <Card
        sx={{
          borderLeft: `5px solid ${color}`,
          transition: "0.3s ease-in-out",
          boxShadow: "none",
          width: "100%",
          "&:hover": {
            borderLeft: `50px solid ${color}`,
            transition: "0.3s ease-in-out",
          },
        }}
      >
        <CardContent>
          <Typography noWrap={true}>{name.toUpperCase()}</Typography>
          <Typography variant="h2">{value ?? 0}</Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
