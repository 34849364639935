import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Card, Divider, Typography } from "@mui/material";
import MainLayout from "../../../core/Layout/MainLayout";

import { useForm } from 'react-hook-form';
import TitleLayout from "../../../core/Layout/TitleLayout";
import ContainerLayout from "../../../core/Layout/ContainerLayout";
import PrimaryResponsiveButton from "../../../components/Buttons/PrimaryResponsiveButton";
import SecondaryResponsiveButton from "../../../components/Buttons/SecondaryResponsiveButton";
import PlateFieldForm from "../../../components/Form/components/Fields/PlateFieldForm";
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SearchTrack } from "../services";
import RegisterTrackTeleservicesForm from "../components/SearchTrack/Teleservices/RegisterTrackTeleservicesForm";
import SimpleInputFieldForm from "../../../components/Form/components/Fields/SimpleInputFieldForm";
import GridDataDisplay from "../../../components/DataDisplay/GridDataDisplay";
import { setCPFMask } from "../../../utils/CPFUtil";
import { setPhoneMask } from "../../../utils/PhoneUtil";
import { sanitizerName } from "../../../utils/StringsManipulationsUtil";


type Props = {
  readonly children: React.ReactNode, 
  
}
export function GridContainerResult ({children}: Props){
  return (
  <Grid container p={1}>
    <Grid size={{xs:12}}>
      <Card elevation={0} 
        sx={{
          borderRadius:'8px' ,
          p:1,
          width: '100%'
          }}> 
          {children}
      </Card>
    </Grid> 
  </Grid> )  

}
export default function SearchTracksPage() {

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues
  } = useForm({ mode: "all" });

  const [processing, setProcessing] = useState<boolean>(false);

  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [showHasNotSearch, setShowHasNotSearch] = useState<boolean>(true);
  const [citizien, setCitizien] = useState<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
      noSsr: true
  });

  const submitForm = async (body: any) => {
    setProcessing(true);

    setShowHasNotSearch(false)
    setIsSearch(false)
      const response  = await SearchTrack(body?.plate?.replace('-', ''), body?.keyword) as any
      if(!response?.hasError){
        setIsSearch(true)
        ///console.log(response.response.data.user.name,'response')
        if(response.response.data.user !== undefined){
          setCitizien(response.response.data.user)
        }
      }else{
        setIsSearch(true)
        setShowHasNotSearch(true)
        setCitizien(null)
      }


    setProcessing(false);
  };

  const clearFileds = () => {
    setValue('plate', '')
    setValue('keyword', '')
    setIsSearch(false)
    setShowHasNotSearch(true)
  }


  return (
    <MainLayout>
       <TitleLayout >
          Ativar Rastreamento
        </TitleLayout>
    
            <ContainerLayout minHeight="auto" loading={processing}>
            
              {/* <Grid container justifyContent={"center"} >
                <Grid size={{xs:12, md: 5}}> */}
                <Grid container component="form" sx={{padding: 2, width: '100%'}} spacing={2} onSubmit={handleSubmit(submitForm)}>
                      <Grid size={{xs:12, md:3}} >
                        <PlateFieldForm
                          register={register}
                          control={control}
                          name='plate'
                          value={getValues('plate')}
                          setValue={setValue}
                          id='plate'
                          required={true}
                          disabled={!showHasNotSearch}
                        />
                      </Grid>
                  <Grid size={{xs:12, md:3}} >
                    <SimpleInputFieldForm
                      label="Chave de Rastreamento"
                      name="keyword"
                      register={register}
                      control={control}
                      messageError={"O campo não pode ser vazio"}
                      setValue={setValue}
                      id='keyword'
                      value={getValues('keyword')}
                      required={true}
                      disabled={!showHasNotSearch}
                      
                    />
                  </Grid>
                  <Grid size={{xs:12, md:6}} >
                    <Grid container spacing={2} sx={{  height: isMobile? 'auto': '40px' }} alignItems={'center'} justifyContent={isMobile?'center':'flex-start'}>
                
                      <SecondaryResponsiveButton onClick={clearFileds}>
                        Limpar
                      </SecondaryResponsiveButton>
                  
                      <PrimaryResponsiveButton  type="submit"> 
                        <SearchIcon />Buscar
                      </PrimaryResponsiveButton>
                  </Grid>
                </Grid> 
                </Grid>
                {/* </Grid>
                </Grid>     */}
                {isSearch && (
                  <Grid size={{xs:12}}>
                    <Divider/>
                  </Grid>

                )} 
                  {isSearch && ( 
                  <>
                      {!showHasNotSearch ? (
                        <>
                        <GridContainerResult>

                          <Typography component='p' sx={{margin: 0, marginBottom:1,  marginTop:1}}>
                            Essa placa está cadastrada no programa Moto Segura, vinculada ao cidadão:         
                          </Typography>
                          <Grid container sx={{pb:1}}>
                            <Grid size={{xs:12, md:4}}>
                              <GridDataDisplay titulo="Nome" info={sanitizerName(citizien?.name)}/>
                              <GridDataDisplay titulo="CPF" info={setCPFMask(citizien?.cpf)}/>
                              <GridDataDisplay titulo="Telefone" info={setPhoneMask(citizien?.phone)}/>
                            </Grid>
                          </Grid>
                          <RegisterTrackTeleservicesForm plate={getValues('plate')} keyword={getValues('keyword')}/>
                        </GridContainerResult>
                        </>
                      ):(
                        <GridContainerResult>
                          <Typography component='h6' sx={{margin: 0, marginBottom:2,  marginTop:2}}>
                              Não existe essa placa e/ou chave de rastreamento cadastrado no programa Moto Segura.                      
                          </Typography>
                        </GridContainerResult>
                      )}
                 </>
                )}   
          
            </ContainerLayout>

    </MainLayout>
  );
}

