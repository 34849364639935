import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';

import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';

import TitleLayout from '../../../core/Layout/TitleLayout';
import {BarChart} from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts';
import { listAllTracker } from '../../Tracks/services';
import dayjs from 'dayjs';
import AbsoluteNumbersCards from '../../Dashboard/components/AbsoluteNumbersCards';
import SubtitleLayout from '../../../core/Layout/SubtitleLayout';
const label = [1,2,3]
const data = [ 16,36,39]

export default function PanelPage() {
  const [isLoading, setIsLoading] =useState<boolean>(true);
  const [rowCount, setRowCount] = useState<number>(0);
  const [dataTreatment, setDataTreatment] = useState<any>([]);
  const [dataToPlot, setDataToPlot] = useState<any>([]);
  const [labelToPlot, setLabelToPlot] = useState<any>([]);
  const statusColor = {
    0: "black",
    1: "#baffd0",
    2: "#f7fb7b",
    3: "#f3c26e",
    4: "#ffa89b",
  };

  const getAllTrack = async () => {
    setIsLoading(true)
    const response = await listAllTracker(0, 50, [], {})
    if(!response.hasError){
      const month = dayjs().month()
      const listTrack = response.response?.data?.data;
      const sortByDate = listTrack.sort((a:any, b:any) => { 
        return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      })
      //const sortByDate = listTrack.sort(({date: a}, {date: b}) => a < b ? -1 : a > b ? 1 : 0)
      console.log(sortByDate, 'sortByDate')
      setDataTreatment(
        sortByDate.map((dt:any)=> dt.created_at )
        .filter((d:any)=> dayjs( d).month()=== month)
        .map((d:any)=>dayjs(d).day()))
    
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem track')
    }
  } 

  const organizeData = ( ) => {
    const result = dataTreatment.reduce((objeto:any, d: number) => {
     // console.log(d,'tratando', objeto)
      return objeto.set(d, (objeto.get(d) || 0) + 1)
     // return objeto[d] ? ++objeto[d] : objeto[d] = 1, objeto
    }, new Map())
    if(result !== undefined && result !== null){
      console.log([...result.values()],'result')
      console.log([...result.keys()],'keyus')
     // console.log(result.values(),'result')
      setDataToPlot([...result.values()])
      setLabelToPlot([...result.keys()])
      setIsLoading(false)
      //console.log(result,'result')
      
    }
   
  }

  useEffect(()=>{
    getAllTrack()
  }, [])


  useEffect(()=>{

   if(dataTreatment.length > 0)
    organizeData()
  }, [dataTreatment])

  return (
    <MainLayout>
        <TitleLayout >
          Relatórios
        </TitleLayout>
        <ContainerLayout>
          <SubtitleLayout sx={{fontWeight: 'bold'}}>Totalizador</SubtitleLayout>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 2, sm: 4, md: 10 }}
        >
          <AbsoluteNumbersCards
            color={statusColor[0]}
            name="Rastreadores Instalados"
            value={2}
          />
        </Grid>
        <SubtitleLayout sx={{fontWeight: 'bold'}}>Status por último envio de localização</SubtitleLayout>
        <Grid
          container
          spacing={{ xs: 2, md: 2 }}
          columns={{ xs: 2, sm: 4, md: 10 }}
        >
          <AbsoluteNumbersCards
            color={statusColor[1]}
            name="há menos de 15 min"
            value={2}
          />
          <AbsoluteNumbersCards
            color={statusColor[2]}
            name="há menos de 6 horas"
            value={2}
          />
          <AbsoluteNumbersCards
            color={statusColor[3]}
            name="há menos de 4 dias"
            value={2}
          />
          <AbsoluteNumbersCards
            color={statusColor[4]}
            name="há mais de 4 dias"
            value={2}
          />
        </Grid>
          <Grid container>
            <Grid size={{xs:12, md:6}}>
              <BarChart
                //dataset={data}
                loading={isLoading}
                  colors={['#009490']}
                  xAxis={[{ 
                    scaleType: 'band', 
                    dataKey: 'day',
                    data: labelToPlot,
                    //dataKey: 'year' 
                  
                  }]}
                  series={[{ data: dataToPlot }]}
                    
                  width={500}
                  height={300}
                />
            </Grid>
            <Grid size={{xs:12, md:6}}>
            <PieChart
              series={[
                {
                  data: [
                    { id: 0, value: 10, label: 'series A' },
                    { id: 1, value: 15, label: 'series B' },
                    { id: 2, value: 20, label: 'series C' },
                  ],
                },
              ]}
              width={400}
              height={200}
            />
            </Grid>
         
          </Grid>
         
        
        </ContainerLayout>
    </MainLayout>



  );
}
