import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Chip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid2';

import GridDataDisplay from '../../../../components/DataDisplay/GridDataDisplay';
import dayjs from 'dayjs';
import LabelTextBoldDataDisplay from '../../../../components/DataDisplay/Texts/LabelTextBoldDataDisplay';
import LabelTextDataDisplay from '../../../../components/DataDisplay/Texts/LabelTextDataDisplay';
import LabelLastUpdadeTracks from '../Card/LabelLastUpdadeTracks';
import SplashFeedback from '../../../../core/Feedbacks/SplashFeedback';
import PrimaryButton from '../../../../components/Buttons/PrimaryButton';
import { setPhoneMask } from '../../../../utils/PhoneUtil';
import { sanitizerName } from '../../../../utils/StringsManipulationsUtil';

type MoreInfoDataGridModalProps = {
  track: any;
  onHandleClose: () => void
}
export default function MoreInfoDataGridModal({
  track = null,
    onHandleClose = ()=>{} 
}:MoreInfoDataGridModalProps) {

  const getStringStatus = (statusText: any) => {      
    return <span style={{fontWeight: 600, color: statusText === 'activated'? 'green': 'orange'}}>{statusText === 'activated'? 'Ativado': 'Desativado'}</span>
  }

  const getLocationString =( ) => {   
    if(track?.companyBranch)
    return `${track?.companyBranch?.company_branch_name}`   

    return 'Não Informado'
  }

  return (
      <Dialog
        open={Boolean(track)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
      >
        <DialogTitle>
         Rastreador
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onHandleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{pt:0}}>
          <Grid container >
            {track?.image !== undefined && track?.image !== null && (
              <Grid size={{xs:12}} sx={{pb:2}} justifyContent={'center'} container>
                <img alt="Imagem do veículo do cidadão" src={track?.image} style={{ width: 180, height: 180, objectFit:'cover' }}/>
              </Grid>
            )}
            <Grid size={{xs:12}}>
              <GridDataDisplay titulo='Cidadão' info={sanitizerName(track?.user?.name)}/>
              <GridDataDisplay titulo="Telefone" info={setPhoneMask(track?.user?.phone)}/>

              <GridDataDisplay titulo='Placa' info={track?.plate}/>
              <GridDataDisplay titulo='Código Rastreamento' info={track?.trackCode}/>
              <GridDataDisplay titulo='Chip' info={track?.chipCode}/>
              <GridDataDisplay titulo='Marca' info={track?.brand}/>
              </Grid>
         
              <Grid size={{xs:12}}>
              <GridDataDisplay titulo='Local de Instalação' info={getLocationString()}/>
              <GridDataDisplay titulo='Criado em' info={dayjs(track?.created_at).format("DD/MM/YYYY HH:mm:ss")}/>
              <Grid container sx={{pb:1}}>
                <LabelTextBoldDataDisplay>
                  Status do Agendamento:
                </LabelTextBoldDataDisplay>
                <LabelTextDataDisplay>
                  {getStringStatus(track?.status)}
                </LabelTextDataDisplay>
              </Grid>
             
              <Grid container sx={{pb:1}}>
                <LabelTextBoldDataDisplay>
                  Última Atualização:
                </LabelTextBoldDataDisplay>
                <LabelTextDataDisplay>
                  <LabelLastUpdadeTracks trackday={track?.recentUpdate} />
                </LabelTextDataDisplay>
              </Grid>
            </Grid>
            </Grid>
         

        </DialogContent>
        <DialogActions sx={{ pr:3, pb: 2.5}}>
          <PrimaryButton onClick={onHandleClose}  >
            Fechar
          </PrimaryButton>
        </DialogActions>
      </Dialog>

  );
}